/* Footer styles */
.footer {
  background-color: #1e1e1e; /* Dark background for the footer */
  padding: 20px 0; /* Vertical padding */
  color: #e0e0e0; /* Light text color */
  border-top: 2px solid #03dac6; /* Top border for separation */
}

.footer-copywright h3 {
  margin: 0; /* Remove default margin */
  text-align: center; /* Center align copyright text */
  font-size: 1rem; /* Font size for copyright */
}

.footer-stock-ticker,
.footer-social-media {
  display: flex; /* Flex display for alignment */
  justify-content: center; /* Center content */
  align-items: center; /* Center vertically */
  flex-direction: column; /* Stack items vertically */
  text-align: center; /* Center align text */
  font-size: 1.1rem; /* Slightly larger font for readability */
}

.footer-stock-ticker h4,
.footer-social-media p {
  margin: 0; /* Remove default margin */
}

.footer-stock-ticker h4 {
  color: #03dac6; /* Color for stock price */
  display: flex; /* Flex for stock symbol and price alignment */
  align-items: center; /* Align items vertically */
}

.footer-social-media p {
  color: #03dac6; /* Color for quotes */
  font-style: italic; /* Italicize quotes for distinction */
}

/* Responsive styles */
@media (max-width: 768px) {
  .footer {
    padding: 15px 0; /* Adjust padding for mobile */
  }

  .footer-copywright h3 {
    font-size: 0.9rem; /* Smaller font size for mobile */
  }

  .footer-stock-ticker h4,
  .footer-social-media p {
    font-size: 1rem; /* Adjust font size for mobile */
  }
}
