.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34; /* Dark background for the header */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb; /* Light blue link color */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Navbar Styles */
.navbar {
  background-color: #003366; /* Dark blue for the navbar */
  transition: background-color 0.3s ease;
}

.sticky {
  background-color: #002244; /* Darker blue for sticky state */
}

.navbar-nav .nav-link {
  color: white; /* White text for links */
  margin-right: 15px;
  transition: color 0.3s ease;
}

.navbar-nav .nav-link:hover {
  color: #66b3ff; /* Light blue on hover */
}

.navbar-toggler {
  border-color: rgba(255, 255, 255, 0.5);
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=UTF8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}


/* Adjustments for screen widths between 770px and 993px */
@media (min-width: 768px) and (max-width: 993px) {
  .navbar {
    height: auto; /* Ensure the navbar doesn't take too much height */
    padding: 10px 0; /* Reduce padding to make the navbar smaller */
  }

  .main-content {
    padding-top: 80px; /* Add padding to the top of the main content */
  }
}

@media (max-width: 768px) {
  .navbar-nav .nav-link {
    padding: 8px 0;
    margin-right: 0;
  }

  .main-content {
    padding-top: 10px; /* Increase the padding for mobile to avoid clashes */
  }
}

.navbar-toggler {
  padding: 10px; /* Increase padding for a larger clickable area */
  border: none; /* Remove default border */
  background-color: transparent; /* Transparent background */
}

.navbar-toggler-icon {
  background-color: #fff; /* White background for better visibility */
  border-radius: 5px; /* Rounded corners for a better look */
  height: 3px; /* Increase the height of the toggle lines */
  width: 30px; /* Increase the width of the toggle lines */
}

.navbar-toggler:hover {
  background-color: #005599; /* Light background on hover */
}

/* Dropdown Menu Styles */
.navbar-nav .dropdown-menu {
  background-color: #004080; /* Dark blue background for the dropdown */
  border-radius: 0.5rem; /* Rounded corners for dropdown */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Subtle shadow for depth */
}

.navbar-nav .dropdown-item {
  color: #ffffff; /* White text color for dropdown items */
  transition: background-color 0.3s ease; /* Smooth transition for background color */
  padding: 10px 15px; /* Padding for dropdown items */
}

.navbar-nav .dropdown-item:hover {
  background-color: #005599; /* Lighten background color on hover */
  color: #ffffff; /* Keep text color white on hover */
}

.navbar-nav .dropdown-item:focus {
  background-color: #003366; /* Maintain background color on focus */
  color: #ffffff; /* Keep text color white on focus */
}
