.contact-us-container {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
    transition: transform 0.3s ease;
  }
  
  .contact-us-container h1 {
    text-align: center;
    color: #333;
  }
  
  .contact-us-container div {
    margin-bottom: 15px;
  }
  
  .contact-us-container label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .contact-us-container input,
  .contact-us-container textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .contact-us-container button {
    background-color: #28a745;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .contact-us-container button:hover {
    background-color: #218838;
    transform: translateY(-2px);
  }
  
  .swoosh {
    animation: swoosh 0.5s ease-in-out forwards;
  }
  
  @keyframes swoosh {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(0);
    }
  }