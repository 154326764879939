.block-stacker-container * {
  margin: 0 !important;
  padding: 0 !important;
  box-sizing: border-box;
}

/* Reset for the block-stacker-container itself */
.block-stacker-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
  margin: 0 !important;
}

.score-board {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.game-area {
  display: grid;
  grid-template-rows: repeat(20, 30px); /* Set cell height */
  grid-template-columns: repeat(10, 30px); /* Set cell width */
  background-color: transparent !important; /* No background */
  padding: 0 !important;
  margin: 0 !important;
  border: none !important;
  width: 300px; /* Total width = cell width * number of columns */
  height: 600px; /* Total height = cell height * number of rows */
}

.grid-row {
  display: flex;
}

.cell {
  width: 30px !important; /* Set exact size */
  height: 30px !important; /* Set exact size */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border: 1px solid #aaa;
}

.cell.filled {
  background-color: #d3d3d3;
}

.controls {
  margin-top: 20px;
}

.on-screen-controls {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.control-btn {
  padding: 10px;
  margin: 0 5px;
  font-size: 16px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.control-btn:hover {
  background-color: #45a049;
}

.restart-btn {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #ff9800;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.restart-btn:hover {
  background-color: #e68900;
}

.game-over {
  color: red;
  font-weight: bold;
}

@media (max-width: 600px) {
  .cell {
    width: 20px;
    height: 20px;
  }

  .control-btn {
    padding: 8px;
    font-size: 14px;
    width: 40;
  }

  .restart-btn {
    padding: 8px 16px;
    font-size: 14px;
  }
}
