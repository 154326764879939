.game-area {
  position: relative;
  width: 100%;
  height: 100vh;
  background-color: #f0f0f0;
  overflow: hidden;
  text-align: center;
}

.leaderboard {
  margin-bottom: 20px;
}

.score-board {
  font-size: 18px;
}

.game-over {
  color: red;
  font-weight: bold;
}

.technologies {
  position: relative;
  width: 100%;
  height: 100%;
}

.tech-icon {
  position: absolute;
  font-size: 40px;
  animation: fly-up 5s linear infinite;
}

@keyframes fly-up {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(-100%);
  }
}

.controls {
  margin-top: 20px;
}

button {
  padding: 10px 20px;
  font-size: 16px;
}
