/* SnakeGame.css */
.snake-game-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.game-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.game-content {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  border: 2px solid #333;
  align-items: center;
}

.grid-row {
  display: flex;
}

.cell {
  width: 30px;
  height: 30px;
  border: 1px solid #aaa;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e0e0e0;
}

.cell.snake {
  background-color: #4caf50;
}

.cell.food {
  background-color: #f44336;
  color: white;
}

.controls {
  margin-top: 20px;
  text-align: center;
}

.restart-btn {
  margin-top: 10px;
  padding: 10px 20px;
  font-size: 1em;
  background-color: #ff9800;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.restart-btn:hover {
  background-color: #e68900;
}

/* On-Screen Controls */
.onscreen-controls {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.onscreen-controls .left-right-controls {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
}

.control-btn {
  padding: 15px 25px;
  font-size: 1.2em;
  background-color: #1f4b99;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.control-btn.up {
  margin-bottom: 10px;
}

.control-btn:hover {
  background-color: #3a6ebf;
}

/* Leaderboard Styles */
.leaderboard {
  margin-top: 30px;
  width: 100%;
  max-width: 600px;
}

.leaderboard h3 {
  margin-bottom: 10px;
  color: #1f4b99;
}

.leaderboard ul {
  list-style: none;
  padding: 0;
}

.leaderboard li {
  background-color: #f0f8ff;
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 5px;
  font-size: 1.1em;
}

/* Responsive design */
@media (max-width: 600px) {
  .cell {
    width: 20px;
    height: 20px;
  }

  .restart-btn {
    padding: 8px 16px;
    font-size: 14px;
  }

  .control-btn {
    padding: 10px 15px;
    font-size: 1em;
    width: 50px;
    height: 50px;
  }

  .onscreen-controls .left-right-controls {
    gap: 5px;
  }
}
