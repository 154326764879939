/* SpaceRaiders.css */

/* Space Raiders container */
.space-raiders-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: visible;
  padding: 20px;
}

.game-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: visible;
  text-align: center;
  width: 100%;
}

.game-content {
  width: 100%;
  margin: 20px 0;
}

/* Adjusted grid */
.grid {
  display: grid;
  grid-template-columns: repeat(15, 1fr);
  gap: 2px;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  background-color: #e0e0e0;
  border: 2px solid #ccc;
  border-radius: 5px;
  position: relative;
}

.cell {
  background-color: #fff;
  position: relative;
}

.cell::before {
  content: '';
  display: block;
  padding-top: 100%; /* Maintain square cells */
}

.cell > div {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.spaceship {
  background-color: blue;
  color: white;
  font-size: 1.2em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.technology {
  background-color: gray;
  color: white;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Controls */
.controls {
  margin-top: 20px;
  text-align: center;
  width: 100%;
}

.restart-btn {
  margin-top: 10px;
  padding: 10px 20px;
  font-size: 1em;
  background-color: #ff9800;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.restart-btn:hover {
  background-color: #e68900;
}

/* On-Screen Controls */
.onscreen-controls {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.onscreen-controls .left-right-controls {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
}

.control-btn {
  padding: 15px 25px;
  font-size: 1.2em;
  background-color: #1f4b99;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.control-btn.up {
  margin-bottom: 10px;
}

.control-btn:hover {
  background-color: #3a6ebf;
}

/* Leaderboard Styles */
.leaderboard {
  margin-top: 30px;
  width: 100%;
  max-width: 600px;
}

.leaderboard h3 {
  margin-bottom: 10px;
  color: #1f4b99;
}

.leaderboard ul {
  list-style: none;
  padding: 0;
}

.leaderboard li {
  background-color: #f0f8ff;
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 5px;
  font-size: 1.1em;
}

/* Responsive design */
@media (max-width: 600px) {
  .grid {
    max-width: 100%;
    gap: 1px;
  }

  .control-btn {
    padding: 10px 15px;
    font-size: 1em;
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }

  .onscreen-controls .left-right-controls {
    gap: 5px;
  }
}
